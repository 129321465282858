import { useState } from "react";
import Beneficios from "./Beneficios";
import ImageApps from "./utils/imageApps/ImageApps";
import { StarIcon, QueueListIcon } from "@heroicons/react/24/solid";
import { ArrowTopRightOnSquareIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { useRecoilState } from "recoil";
import { planoSelecionadoState } from "../atoms/planoSelecionadoAtom";
import { passoCarrinhoState } from "../atoms/passoCarrinhoAtom";
import { Navigate } from "react-router";
import { getCentavosValor, getDezenasValor, getMedidaDados, getNumDados } from "../utils/separacaoTexto";
import Img from "./utils/imageApps/Img";
import { pushEventToDataLayer } from "../utils/gtm";
import BonusInternet from "./utils/BonusInternet";
import BeneficiosLista from "./utils/BeneficiosLista";
import { formataItemPrice } from "../utils/textUtils";
import BannerEspecial from "./utils/BannerEspecial";
import Switcher from "./utils/Switcher";
import { processoState } from "../atoms/processAtom";

export default function Plano({ melhorOferta, oferta, telefoneCliente }) {
  const permiteSelecaoFidelidade = !!oferta.permiteSelecaoFidelizacao;
  const [processo] = useRecoilState(processoState);

  const [showBeneficios, setShowBeneficios] = useState(false);
  const [planoSelecionado, setPlanoSelecionado] = useRecoilState(planoSelecionadoState);
  const [, setPassoCarrinho] = useRecoilState(passoCarrinhoState);
  const [fidelizado, setFidelizado] = useState(oferta.fidelizado);
  const [valorExibido, setValorExibido] = useState(
    processo.fluxo === "rentabilizacao" || (oferta.fidelizado && processo.fluxo === "aquisicao") ? oferta.valorFinal : oferta.valor
  );
  const descontoOferta = oferta.descontoOferta;

  const handleSelecionaPlano = () => {
    setPassoCarrinho(1);
    setPlanoSelecionado({ ...oferta, fidelizado, desconto: fidelizado ? descontoOferta : 0 });

    pushEventToDataLayer({
      event: "add_to_cart",
      currency: "BRL",
      value: formataItemPrice(oferta?.valorFinal),
      ecommerce: {
        items: [
          {
            item_name: oferta?.planoDestinoExibicao,
            item_id: oferta?.planoDestinoExibicao + (oferta?.fidelizado ? "_f" : ""),
            item_price: formataItemPrice(oferta?.valorFinal),
            item_brand: oferta?.tipo,
            item_category: oferta?.destaque,
            quantity: 1,
            item_variant: oferta?.beneficiosPlano,
            currency: "BRL",
          },
        ],
      },
    });
  };

  const handleShowBeneficios = () => {
    setShowBeneficios(true);
  };
  const handleShowRegulamento = () => {};

  const handleChangeFidelizacao = (valor) => {
    setFidelizado(valor);

    if (valor) {
      setValorExibido(oferta.valorFinal);
    } else {
      setValorExibido(oferta.valor);
    }
  };

  console.debug("oferta", oferta);
  return (
    <>
      {planoSelecionado?.tipo && <Navigate replace to="/carrinho" />}
      <div className="w-72">
        <div className="bg-white rounded-xl h-[550px] w-72 flex flex-col">
          {oferta && (
            <>
              {melhorOferta ? (
                <div className="relative w-11/12 p-1 mx-auto mt-0 rounded-full bg-verde-tim">
                  <span className="absolute text-xs font-medium text-verde-tim top-2 right-2">#melhoroferta</span>
                </div>
              ) : (
                <div className=" "></div>
              )}
              {oferta.especial && <BannerEspecial mensagem={oferta.observacao} />}
              {/* Preço */}
              <div className="flex flex-row items-center justify-around px-4 mt-2 text-secondary-tim">
                <div className="flex flex-col">
                  <span className="mt-1 text-xs font-medium">Até</span>
                  <span className="-mt-2 text-5xl font-extrabold">
                    {getNumDados(oferta.dadosTotal)}
                    <span className="text-3xl">{getMedidaDados(oferta.dadosTotal)}</span>
                  </span>
                </div>
                <div className="flex flex-col items-center mt-4 ">
                  <div className="flex flex-row items-center">
                    <span className="block mr-1 text-sm font-medium ">R$</span>
                    <span className="text-4xl font-extrabold text-secondary-tim-light">{getDezenasValor(valorExibido)}</span>
                    <div className="flex flex-col ">
                      <span className="text-sm font-extrabold">,{getCentavosValor(valorExibido)}</span>
                      <span className="-mt-1 text-xs font-medium ">/mes</span>
                    </div>
                  </div>
                  {oferta?.express && (
                    <span className="p-0.5 px-1 text-xs font-medium text-center text-white bg-primary-tim-light">No cartão de crédito</span>
                  )}
                </div>
              </div>

              <div className="flex flex-col justify-between px-4 py-2 grow">
                {/* Nome do plano */}
                <div className="">
                  <h2 className="text-sm font-medium text-secondary-tim-light ">{oferta.planoDestinoExibicao}</h2>
                  {(oferta.deezer || oferta.apps) && <BonusInternet lista={false} oferta={oferta} />}
                </div>

                {/* apps incluidos */}
                {(oferta.deezer || oferta.apps) && (
                  <div className="flex flex-col justify-start mt-2 grow">
                    <>
                      <h5 className="font-light text-md text-primary-tim-light">
                        APPs <span className="font-bold">inclusos</span>
                      </h5>
                      {oferta.deezer && (
                        <div className="">
                          <Img app={"Deezer"} />
                        </div>
                      )}
                      <ImageApps oferta={oferta} />
                    </>
                  </div>
                )}

                {/* Inclui benefícios se nao tiver APP */}
                {!oferta?.apps && (
                  <>
                    <BonusInternet lista={true} oferta={oferta} />
                    <BeneficiosLista oferta={oferta} />
                  </>
                )}
                <div>
                  {/* botao acao */}
                  <div className="flex flex-col items-center px-4 mt-3">
                    {permiteSelecaoFidelidade === true && (
                      <Switcher titulo={"Fidelizado por 12 meses"} valor={fidelizado} comprimido={true} callback={handleChangeFidelizacao} />
                    )}
                    {permiteSelecaoFidelidade === false && (
                      <span className="text-xs text-cinza-neutro text-opacity-90 mb-2">
                        {fidelizado ? "Fidelizado por 12 meses" : "Sem fidelidade"}
                      </span>
                    )}

                    <button
                      onClick={handleSelecionaPlano}
                      className="w-full py-3 text-sm font-extrabold text-white uppercase rounded-full bg-secondary-tim"
                    >
                      Contratar
                    </button>
                  </div>
                  <hr className="mt-6" />
                  {/* Mais beneficios */}

                  <div
                    onClick={handleShowBeneficios}
                    className="flex flex-row items-center justify-between p-4 font-bold shadow-lg cursor-pointer text-primary-tim-light"
                  >
                    <div className="flex flex-row items-center space-x-2 ">
                      <StarIcon className="w-5 text-verde-tim" />
                      <span className="text-xs">Mais beneficios</span>
                    </div>
                    <PlusSmallIcon className="w-6" />
                  </div>

                  {/* Regulamento oferta */}
                  <div
                    onClick={handleShowRegulamento}
                    className="flex flex-row items-center justify-between p-4 font-bold shadow-lg text-primary-tim-light"
                  >
                    <div name="div-oferta-regulamento" className="flex flex-row items-center space-x-2">
                      <QueueListIcon className="w-5" />
                      <a className="inline text-xs" href={oferta.regulamento}>
                        Regulamento da oferta TIM Controle
                      </a>
                    </div>

                    <a className="inline text-xs" target="_blank" href={oferta.regulamento} rel="noreferrer">
                      <ArrowTopRightOnSquareIcon className="w-6" />
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Beneficios extraClass="-left-4" oferta={oferta} exibir={showBeneficios} onClose={() => setShowBeneficios(false)} />
    </>
  );
}
